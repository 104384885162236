<template>
  <v-col :cols="cols" :md="md">
    <h3 v-if="fieldTitle" :class="disabled ? 'grey--text' : 'black--text'">
      <span :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">{{ title }}</span>
      <span v-if="required" class="required"> * </span>
    </h3>
    <v-text-field
      :label="label"
      :outlined="!disabled"
      filled
      :placeholder="title"
      v-model="computedValue"
      :disabled="disabled"
      hide-details="auto"
      :dense="dense"
      :rules="rules"
    />
  </v-col>
</template>

<script>
export default {
  props: ['label', 'cols', 'md', 'placeholder', 'title', 'required', 'value', 'disabled', 'dense', 'fieldTitle', 'rules'],
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
};
</script>
