import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import routesModel from './routes'
import global from "../store/global";
Vue.use(VueRouter);

const routes = [
  ...routesModel,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          selector: to.hash,
          offset: { x: 0, y: 10 } 
        };
      } else {
        return { x: 0, y: 0 };
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  global.dispatch("reset");
  if (to.matched.some((r) => r.meta.requireAuth)) {
    if (store.state.token == undefined || store.state.token == null) {
      next("/home");
    }
    if(to.meta.auth.find(x => x == store.state.user.userType.name) == undefined){
      next("/home");
    }
    next();
  }
  from.meta.savedPosition = document.documentElement.scrollTop || document.body.scrollTop;

  next();
});

export default router;
