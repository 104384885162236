<template>
  <v-col :cols="cols" :md="md">
    <h3 v-if="fieldTitle" :class="disabled ? 'grey--text' : 'black--text'"><span :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">{{ title }}</span><span v-if="required" class="required"> * </span></h3>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedValue"
          :label="label"
          :outlined="!disabled"
          filled
          :placeholder="title"
          hide-details="auto"
          :dense="dense"
          readonly
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          append-icon="fi fi-rr-calendar"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="computedValue"
        :active-picker.sync="activePicker"
        min="1950-01-01"
        no-title
        scrollable
        @change="save"
        locale="ar"
        color="primary"
      ></v-date-picker>
    </v-menu>
  </v-col>
</template>

<script>
export default {
  props: ['label', 'cols', 'md', 'placeholder', 'title', 'required', 'value', 'disabled', 'dense' , 'fieldTitle'],
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  data() {
    return {
      activePicker: null,
      menu: false,
    };
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>
