import Vue from 'vue';
import Vuex from 'vuex';
import { toastification } from "./modules/toastification.module";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: JSON.parse(sessionStorage.getItem('user')),
		token: sessionStorage.getItem('token'),
		medicalCenterId: sessionStorage.getItem('medicalCenterId'),
		clinicId: sessionStorage.getItem('clinicId'),
		dialog: false,
		dialog2: false,
		dialog3: false,
		dialog4: false,
		itemDetails: {},
		itemData: {},
		dialogType: 'add',
		defaultPhoto: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAXVBMVEXl5eUAAADq6uqUlJTo6Oju7u7Z2dne3t66urrDw8OJiYmioqJ7e3upqanGxsZWVlYyMjJRUVFubm6Dg4NpaWl4eHhMTExAQEAtLS1dXV0KCgqxsbEXFxchISGQkJDcfo/pAAADDklEQVR4nO3c6XKCMBiFYRMTQFHctbXL/V9mxyrgUgLqWJIv7/NXxskhAbLBYAAAAAAAAAAAAAAAAAAAAAAAAAAAkEa79V2859l86JLbvgv4LPut3L4Dj2jzloBKhV2L9qM1oFIfAUfUWYeASmXB3m500SmgUkWgEXWy75hwnwQZ0aSfHQMq9Zmavot7PzP46hxQqa+B7xH/6Kzs7gio1M7r7o6xyfSms7K4K6BSi5t/mCbWk4q1xezONF3NCi8elXbyonwHEw8i2vcXBlTqvfeIL63Bg75r0XTttjyu6Pd2Y191k6nN+q3E5OUBlUr6DKin/5Bw2uejXw//IeGw14RtcxThJ5RfhyQkobSE88VyMpksF3ORCd9WWartkU6z1ZuwhOtC6/P+pdG6WAtKuP5rtG5s0i2j/wnno4bZCGNHXa5I7xNuTHMJtdmEn3DqHvzY9r675wmzttGdbV3b8DvhVUDzOw96eVW2RvQ64UUT1TbN8vF4nGepPS90W0P1OeHmLKAe5Nvqh20+OCu2dd9uPE44r5uj0fnVQlR+1liN86HhccJRVTSd3K5i7OolNT0KM+G6aqMNCeozYF29G38TJmUzbKyiKqJxzdl5m7CqQkfxq5PgqkRvE1Zz1Y6VxF1ViY65c18TvpXl0q4tNXl1VPN40deEq6pcjoBqX/3TKriE2amROquwrkTT3HfzNWF6OsRuGw852Jb3ozS0hPOyWM0lvzwTurFf42nCxaluHK3vqGzNtnFrg6cJl6eELZdhfSHaZWAJy8VpPW5JOC4TNi6X+5rwVCzBCcXXofzrUP69VP7zUH6fJoJ+abexhSoPsuGNLeSPD+WP8SOYp5E/1xbBfKn8Oe+rdYvL3/Yi1i3krz1FsH4YwRpwBOv4EezFiGA/TQR7opT8fW2/GYXvTTwo95dqoftLj2TvEX4cCUlIQhJKTyj+/UP575BG8B6w/He55b+PH8E3FSL4LkYE3zZp+D7N8/z5Ps1By8cRH9N3KAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAvH4AHoU0VY5sEU4AAAAASUVORK5CYII=',
		printDetails: null,
		balance: 0,
		tabs: JSON.parse(localStorage.getItem('tabs')),
	},
	mutations: {
		setDialog(state) {
			state.dialog = !state.dialog
		},
		setDialog2(state) {
			state.dialog2 = !state.dialog2
		},
		setDialog3(state) {
			state.dialog3 = !state.dialog3
		},
		setDialog4(state) {
			state.dialog4 = !state.dialog4
		},
		setItemDetails(state, item) {
			state.itemDetails = item
		},
		setItemData(state, item) {
			state.itemData = item
		},
		authUser(state, userRole) {
			state.user = userRole.user;
			state.token = userRole.token;
			state.medicalCenterId = userRole.medicalCenterId;
			state.clinicId = userRole.clinicId;
		},
		updateTabs(state, data) {
			state.tabs = data.tabs;
		},
		setData(state, printDetails) {
			state.printDetails = printDetails;
		},
		
	}, 
	actions: {
		login({ commit }) {
			commit('authUser', {
				user: JSON.parse(sessionStorage.getItem('user')),
				token: sessionStorage.getItem('token'),
				medicalCenterId: sessionStorage.getItem('medicalCenterId'),
				clinicId: sessionStorage.getItem('clinicId')
			});
		},

		setTabs({ commit }) {
			commit('updateTabs', {
				tabs: JSON.parse(localStorage.getItem('tabs')),
			});
		},

		print({ commit }, data) {
			commit('setData',data);
		},

		checkPermissin({ commit }, permission) {
			// console.log(permission)
		}


	},
	modules: {
        toastification
    },
});
