export const toastification = {
    namespaced: true,
    state: {
        options: {
            message: null,
            type: null
        }
    },

    mutations: {
        set_toastification(state, item) {
            state.options = item;
            switch (item.type) {
                case "info":
                    this._vm.$toast.info(state.options.message, {
                        position: "top-left",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: true
                    });
                    break;

                case "success":
                    this._vm.$toast.success(state.options.message, {
                        position: "top-left",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: true
                    });
                    break;

                case "warning":
                    this._vm.$toast.warning(state.options.message, {
                        position: "top-left",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: true
                    });
                    break;

                case "error":
                    this._vm.$toast.error(state.options.message, {
                        position: "top-left",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: true
                    });
                    break;

                default:
                    this._vm.$toast(state.options.message, {
                        position: "top-left",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: true
                    });
                    break;
            }
        }
    },

    actions: {
        setToast({ commit }, item) {
            commit("set_toastification", item);
        }
    }
};
