import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '../style/style.scss';
import variabels from '@/style/variabels.scss'
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    icons: {
        iconfont: 'md',
    },    
    theme: {
    dark: false,
      themes: {
        light: {
          primary: variabels.primaryColor,
          secondary: variabels.secondaryColor,
          third: variabels.thirdColor,
          background: variabels.backgroundColor,
          accent: variabels.accentColor,
          error: variabels.errorColor,
          info: variabels.infoColor,
          success: variabels.successColor,
          warning: variabels.warningColor,
        },

        dark: {
          primary: variabels.primaryColor,
          secondary: variabels.secondaryColor,
          third: variabels.thirdColor,
          background: '#262E35',
          accent: variabels.accentColor,
          error: variabels.errorColor,
          info: variabels.infoColor,
          success: variabels.successColor,
          warning: variabels.warningColor,
          
        },
        
      },
    },
});
