import global from "../store/global";
export default [
  {
    path: "/pageNotFound",
    name: "pageNotFound",
    component: () => import("../views/404.vue"),
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: "/home",
    name: "الرئيسية",
    component: () => import("../views/home.vue"),
    meta: {
      requireAuth: false,
      hideNavigation: true,
      homePage: true,
    },
  },
  {
    path: "/cover",
    name: "غلاف الصورة الرئيسية",
    component: () => import("../views/cover/page.vue"),
    meta: {
      single: "غلاف الصورة الرئيسية",
      requireAuth: true,
      endPoint: ['cover'],
      auth: ["admin"]
    },
  },
  {
    path: "/sliderClinics",
    name: "نشرة العيادات",
    component: () => import("../views/sliderClinics/page.vue"),
    meta: {
      single: "نشرة عيادة",
      requireAuth: true,
      endPoint: ['sliderClinic'],
      auth: ["admin"]
    },
  },
  {
    path: "/device",
    name: "الاجهزة",
    component: () => import("../views/device/page.vue"),
    meta: {
      single: "جهاز",
      requireAuth: true,
      endPoint: ['device'],
      auth: ["admin"]
    },
  },
  {
    path: "/devices",
    name: "الاجهزة",
    component: () => import("../views/device/all.vue"),
    meta: {
      single: "جهاز",
      requireAuth: false,
      hideNavigation: true,
      homePage: true,
      endPoint: ['device'],
    },
  },
  {
    path: "/departments",
    name: "الاقسام",
    component: () => import("../views/department/all.vue"),
    meta: {
      single: "جهاز",
      requireAuth: false,
      hideNavigation: true,
      homePage: true,
      endPoint: ['device'],
    },
  },
  {
    path: "/device/:id",
    name: "الاجهزة",
    component: () => import("../views/device/details.vue"),
    meta: {
      single: "جهاز",
      requireAuth: false,
      endPoint: ['device'],
      hideNavigation: true,
      homePage: true,
    },
  },
  {
    path: "/department",
    name: "الاقسام",
    component: () => import("../views/department/page.vue"),
    meta: {
      single: "قسم",
      requireAuth: true,
      endPoint: ['department'],
      auth: ["admin"]
    },
  },
  {
    path: "/department/:id",
    name: "الاقسام",
    component: () => import("../views/department/details.vue"),
    meta: {
      single: "قسم",
      requireAuth: false,
      endPoint: ['department'],
      hideNavigation: true,
      homePage: true,

    },
  },
  {
    path: "/post",
    name: "الاخبار",
    component: () => import("../views/post/page.vue"),
    meta: {
      single: "خبر",
      requireAuth: true,
      endPoint: ['post'],
      auth: ["admin"]
    },
  },
  {
    path: "/news",
    name: "الاخبار",
    component: () => import("../views/post/news.vue"),
    meta: {
      single: "خبر",
      requireAuth: false,
      endPoint: ['post'],
      hideNavigation: true,
      homePage: true,
    },
  },
  {
    path: "/news/:id",
    name: "الاخبار",
    component: () => import("../views/post/details.vue"),
    meta: {
      single: "خبر",
      requireAuth: false,
      endPoint: ['post'],
      hideNavigation: true,
      homePage: true,
    },
  },
  {
    path: "/inbox",
    name: "الرسائل",
    component: () => import("../views/inbox/page.vue"),
    meta: {
      single: "رسالة",
      requireAuth: true,
      endPoint: ['inbox'],
      auth: ["employee", "admin"]
    },
  },
  {
    path: "/clinic",
    name: "العيادات",
    component: () => import("../views/clinic/page.vue"),
    meta: {
      single: "عيادة",
      requireAuth: true,
      endPoint: ['clinic'],
      auth: ["admin"]
    },
  },
  {
    path: "/clinic/:id",
    name: "العيادات",
    component: () => import("../views/clinic/details.vue"),
    meta: {
      single: "عيادة",
      requireAuth: false,
      endPoint: ['clinic'],
      auth: ["employee", "admin"],
    },
  },
  {
    path: "/shedual",
    name: "جدول العيادات",
    component: () => import("../views/shedual/page.vue"),
    meta: {
      single: "عيادة",
      requireAuth: false,
      endPoint: ['clinic'],
      hideNavigation: true,
      homePage: true
      
    },
  },
  {
    path: "/reservationReview",
    name: "الحجوزات",
    component: () => import("../views/reservationReview/page.vue"),
    meta: {
      single: "حجز",
      requireAuth: true,
      endPoint: ['Reservation'],
      homePage: false,
      auth: ["employee", "admin"]
    },
  },
  {
    path: "/doctor",
    name: "الاطباء",
    component: () => import("../views/doctor/page.vue"),
    meta: {
      single: "طبيب",
      requireAuth: true,
      endPoint: ['doctor'],
      auth: ["admin"]
    },
  },
  {
    path: "/counters",
    name: "العدادات",
    component: () => import("../views/counters/page.vue"),
    meta: {
      single: "عداد",
      requireAuth: true,
      endPoint: ['counters'],
      auth: ["admin"]
    },
  },
  {
    path: "/ourDoctors",
    name: "الاطباء",
    component: () => import("../views/ourDoctors/page.vue"),
    meta: {
      single: "طبيب",
      requireAuth: false,
      hideNavigation: true,
      endPoint: ['doctor'],
      homePage: true,
    },
  },
  {
    path: "/ourDoctors/:id",
    name: "ملف الطبيب",
    component: () => import("../views/ourDoctors/details.vue"),
    meta: {
      single: "ملف الطبيب",
      requireAuth: false,
      hideNavigation: true,
      endPoint: ['doctor'],
      homePage: true,
    },
  },
  {
    path: "/reservation",
    name: "حجز موعد",
    component: () => import("../views/reservation/page.vue"),
    meta: {
      single: "حجز",
      requireAuth: false,
      hideNavigation: true,
      endPoint: ['inbox'],
      homePage: true,
    },
  },
  {
    path: "/about",
    name: "حول المستشفى",
    component: () => import("../views/about/page.vue"),
    meta: {
      single: "حول المستشفى",
      requireAuth: false,
      hideNavigation: true,
      endPoint: ['inbox'],
      homePage: true,
    },
  },
  {
    path: "/login",
    name: "تسجيل الدخول",
    component: require("../views/auth/login.vue").default,
    meta: {
      hideNavigation: true,
    },
  },
 
  { 
    path: "*",
    meta: {
      requireAuth: true,
    },
    redirect: "/home",
  },
  
]
