<template>
<v-app>
    <div class="context">
        <v-row justify="center" align="center" height="400px">
            <v-col>
                <v-card class="text-center mx-auto" outlined width="500" light>
                    <v-col>
                            <v-img class="mx-auto" width="200" height="100" contain src="@/assets/images/logo.png"></v-img>
                        <v-divider class=" mb-8" />

                        <v-form id="content" @submit.prevent="submit" ref="form" v-model="valid" lazy-validation>
                            <v-text-field v-model="username" prefix=" | " filled label="إسم المستخدم" outlined :rules="[required()]">
                                <template v-slot:prepend-inner>
                                    <user-icon />
                                </template>
                            </v-text-field>
                            <v-text-field v-model="password" prefix=" | " filled label="كلمة المرور" dir="ltr" outlined :rules="[required()]" :append-icon="showPassword ? 'fi fi-rr-eye' : 'fi fi-rr-eye-crossed'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword">
                                <template v-slot:prepend-inner>
                                    <lock-icon />
                                </template>
                            </v-text-field>
                            <v-btn class="btn" :loading="loading" type="submit" block color="primary" x-large>تسجيل الدخول</v-btn>
                        </v-form>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </div>

    <div class="area">
        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>

</v-app>
</template>

<script>
import validations from '@/utils/validations'
import {
    UserIcon,
    LockIcon
} from 'vue-feather-icons'
export default {
    components: {
        UserIcon,
        LockIcon,
    },

    data() {
        return {
            username: '',
            password: '',
            loading: false,
            valid: true,
            showPassword: false,
            ...validations
        }
    },

    created() {
        console.log(`${window.location.protocol}//${window.location.host}`)
    },

    methods: {

        
        async submit() {
            this.loading = true
            try {
                let data = {
                    "userName": this.username,
                    "password": this.password
                }
                let res = await this.$http.post('Login', data)
                console.log(res)
                sessionStorage.setItem('token', res.data.token)
                sessionStorage.setItem('user', JSON.stringify(res.data))
                this.$store.dispatch("login");
                this.$router.push('/inbox')

            } catch {
                this.$service.faild('إسم المستخدم او كلمة المرور خطأ')
            } finally {
                this.loading = false
            }
        },

    }

}
</script>

<style>
#content {
    position: relative;
    z-index: 2;
    padding: 30px;
    text-align: center;
    font-weight: bold;
}

.context {
    width: 100%;
    position: absolute;
    top: 20vh;
}

.context h1 {
    text-align: center;
    color: #fff;
    font-size: 50px;
}

.area {
    background: #FFFFFF;
    width: 100%;
    height: 100vh;

}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 100%;
        border: solid 3px #F495BD;
    }

}
</style>
