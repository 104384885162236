import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './axios';
import service from './service/index';
import vuetify from './plugins/vuetify';
import style from './style/style.scss';
// import uicons from './assets/uicons-regular-rounded/css/uicons-regular-rounded.css'
import validations from '@/utils/validations';
import VueNumeric from 'vue-numeric-currency';
import vueDebounce from 'vue-debounce';
import VueTheMask from 'vue-the-mask';
import global from './store/global';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./utils/filters";
import genericService from "@/service/genericService";
import aAutoComplete from "@/components/fields/aAutoComplete.vue";
import aDatePicker from "@/components/fields/aDatePicker.vue";
import aTextField from "@/components/fields/aTextField.vue";
import aMoneyField from "@/components/fields/aMoneyField.vue";
import aPhoneNumber from "@/components/fields/aPhoneNumber.vue";

Vue.component("aAutoComplete", aAutoComplete);
Vue.component("aDatePicker", aDatePicker);
Vue.component("aTextField", aTextField);
Vue.component("aMoneyField", aMoneyField);
Vue.component("aPhoneNumber", aPhoneNumber);


const optionsT = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
};


Vue.use(Toast, optionsT);


Vue.component('LottieAnimation', () => import('lottie-web-vue'));


Vue.use(VueTheMask);
Vue.prototype.$phoneMask = '+964 ### ### ####';
Vue.prototype.$birthDate = '####/##/##';
Vue.prototype.$money = '#,###.##';
Vue.use(vueDebounce);
Vue.prototype.$service = service;
Vue.use(VueNumeric);

Vue.prototype.$http = axios;
Vue.prototype.$validations = validations;
Vue.prototype.$global = global;
Vue.prototype.$genericService = genericService;

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

Vue.component('empty', () => import('./components/Empty.vue'));
Vue.component('Loading', () => import('./components/Loading.vue'));
Vue.component('Pagination', () => import('./components/Pagination.vue'));
Vue.component('HomePageAppbar', () => import('./components/HomePageAppbar.vue'));

//401
axios.interceptors.response.use(
	response => {
	  if (response.status === 200 || response.status === 201) {
		return Promise.resolve(response);
	  } else {
		return Promise.reject(response);
	  }
	},
  error => {
	  if (error.response.status) {
		if (error.response.status == 401) {
		  router.replace({
			path: "/login",
			query: { redirect: router.currentRoute.fullPath }
		  });
		}
		return Promise.reject(error.response);
	  }
	}
  );
  //401

  async function init() {
	try {
	  const response = await fetch('/config.json');
	  const config = await response.json();
	  axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? config.BASE_URL_PROD +'/api/' : config.BASE_URL_DEV +'/api/' ; 
	  Vue.prototype.$config = config
	  Vue.prototype.$url = process.env.NODE_ENV == 'production' ? config.BASE_URL_PROD +'/' : config.BASE_URL_DEV +'/' ;
	  new Vue({
		router,
		store,
		vuetify,
		// uicons,
		style,
		render: (h) => h(App),
	  }).$mount('#app');
	} catch (error) {
	  console.error('Error loading config.json:', error);
	}
  }
  
  init();
