const text = "هذا الحقل مطلوب";
let required = (propertyType) => {
    return (v) =>
        (v && v.toString() != "") || (propertyType ? propertyType : text);
};
let minLength = (propertyType, minLength) => {
    return (v) =>
        (v && v.length >= minLength) ||
        `${propertyType} يجب أن يكون على الأقل ${minLength} رمز`;
};
let maxLength = (propertyType, maxLength) => {
    return (v) =>
        (v && v.length <= maxLength) ||
        `${propertyType} يجب ان يكون على الأكثر ${maxLength} رمز`;
};

let maxNumber = (maxLength) => {
    return (v) => v <= maxLength || `اكبر قيمة مسموح بها ${maxLength}`;
};

let minNumber = (minNo) => {
    return (v) => v >= minNo || `اقل قيمة مسموح بها ${minNo}`;
};

let emailFormat = () => {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/;
    return (v) => (v && regex.test(v)) || "يجب إدخال بريد إلكتروني صالح";
};

let birthDate = (propertyType) => {
    return (v) => {
        if (!v) {
            return false;
        }
        let date = new Date(v);
        if (isNaN(date.getTime())) {
            return "يجب ادراج تاريخ صالح";
        }
        if (date.getMonth() + 1 > 12) {
            return "يرجى ادراج شهر صحيح";
        }
        if (date.getDate() > 31) {
            return "يرجى ادراج يوم صحيح";
        }
        return true;
    };
};

let phone = (minLength, text) => {
    // console.log(minLength)
    // return (v) => (console.log(v.replace(/\s/g, "")));
    return (v) => (v && v.replace(/\s/g, "").length >= minLength) || `${text}`;
};

export default {
    required,
    minLength,
    maxLength,
    maxNumber,
    minNumber,
    emailFormat,
    birthDate,
    phone,
};
